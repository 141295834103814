import React, { useState } from 'react';
import axios from 'axios';
import { Col } from 'antd';
import Logo from '../assets/alta-1 1.svg'
import { Link, Navigate } from 'react-router-dom';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.patch(
        "https://altablue.cloud/api/auth/user/forgot-password",
        { email }
      );
      setMessage("Link sent successfully");
      setEmail("")
     
      setError("");
    } catch (error) {
      setMessage("");
      setError("Failed to send reset link. Please try again.");
    }
  };

  return (
    <div className="loginpage">
      <Col
        className="loginScreen"
        style={{ paddingBottom: "20px" }}
        xs={20}
        sm={16}
        md={12}
        lg={8}
        xl={8}
      >
        <div className="logo center">
          <img
            style={{width: "140px" }}
               
            
            src={Logo}
          />
        </div>
        <div className="loginContent">
          <h3>Forgot Password</h3>
          <div className="loginInputs">
            <div className="loginInput">
              <p>Email </p>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <button
                onClick={handleSubmit}
                style={{ marginTop: "40px" }}
                className="loginButton"
              >
                Submit
              </button>

              <Link to="/" className="logoutButton center">
                Back To Home
              </Link>
            </div>
          </div>
        </div>
      </Col>
    </div>
  );
};

export default ForgetPassword;

    // <h2>Forgot Password</h2>
    //       <form onSubmit={handleSubmit}>
    //         <label>Email:</label>
    //         <input
    //           type="email"
    //           value={email}
    //           onChange={(e) => setEmail(e.target.value)}
    //           required
    //         />
    //         <button type="submit">Submit</button>
    //       </form>
    //       {message && <p>{message}</p>}
    //       {error && <p>{error}</p>}