import {
  Col,
  Pagination,
  Modal,
  Button,
  Input,
  Checkbox,
  Form,
  Tooltip,
  message,
  Select
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SearchBar from "../searchbar/SearchBar";

const { Option } = Select;

const ManagerList = ({ data, reload }) => {
const [showForm, setShowForm] = useState(false);
const [showEditForm, setShowEditForm] = useState(false);
const [companyNames, setCompanyNames] = useState([]);
const [id,setId] = useState(null);
const [email, setEmail] = useState("");
const [firstName, setFirstName] = useState("");
const [lastName, setLastName] = useState("");
const [password, setPassword] = useState("");
const [confirmPassword, setConfirmPassword] = useState("");
const [error, setError] = useState("");
const [deleteError, setDeleteError] = useState("");
const [companyData, setCompanyData] = useState([]);
const [smallScreen, setSmallScreen] = useState(false);
const { token } = useSelector((state) => state.auth);
const [currentPage, setCurrentPage] = useState(1);
const [editManager, setEditManager] = useState(null);
const [searchQuery, setSearchQuery] = useState("");
const [selectedManager, setSelectedManager] = useState(null);
const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
const [pageSize, setPageSize] = useState(smallScreen ? 7 : 5);
const [currentFilterValue, setCurrentFilterValue] = useState(1);
const [filteredData, setFilteredData] = useState(data ? [...data] : []);

const startIndex = (currentPage - 1) * pageSize;
const endIndex = Math.min(startIndex + pageSize, filteredData.length);

const currentPageData = filteredData.slice(startIndex, endIndex).sort((a, b) => a.email.localeCompare(b.email));

const [form] = Form.useForm();

const handlePageChange = (page) => {
  setCurrentPage(page);
};

const handleDeleteManager = (id) => {
  setSelectedManager(id);
  setIsDeleteModalVisible(true);
};

const handleDeleteModalCancel = () => {
  setIsDeleteModalVisible(false);
  setSelectedManager(null);
  setDeleteError(false);
};

const handlePerPageChange = (value) => {
  let newSize = 5;
  if (value === 2) newSize = 10;
  else if (value === 3) newSize = 15;
  setPageSize(newSize);
};

const handleFilterChange = (value) => {
  let newData = [...data];
  if (value === 1) newData.sort((a, b) => a.email.localeCompare(b.email));
  else if (value === 2)
    newData.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  else if (value === 3)
    newData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  setFilteredData(newData);
  setCurrentFilterValue(value);
};

const handleDeleteManagerSubmit = async () => {
  try {
    const response = await axios.delete(
      `https://altablue.cloud/api/drivermanagers/${selectedManager}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    message.success("Driver Manager deleted successfully");
    setIsDeleteModalVisible(false);
    setDeleteError(false);
    reload();
  } catch (error) {
    message.error("Error deleting driver manager");
    setDeleteError(true);
  }
};

useEffect(() => {
  const handleResize = () => {
    setSmallScreen(window.innerWidth > 1168);
  };
  handleResize();
  window.addEventListener("resize", handleResize);
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://altablue.cloud/api/companies/all",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCompanyData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  if ((showForm || showEditForm) && companyData.length === 0) {
    fetchData();
  }
}, [showForm, showEditForm]);


const handleCompanyChange = (checkedValues) => {
  setCompanyNames(checkedValues);
};

const handleButtonClick = () => {
  setShowForm(true);
};

const handleEditButtonClick = (manager) => {

  setEditManager(manager);
  setEmail(manager.email);
  setId(manager.id);
  setCompanyNames(manager.companies.map(String));
  setShowEditForm(true);
  form.resetFields();
};

const handleSubmit = async () => {
  if (!email || !password || !confirmPassword || !firstName || !lastName) {
    message.error("Enter each field");
    return;
  }
  if (password !== confirmPassword) {
    message.error("Password and confirm password don't match");
    return;
  }
  const companies = companyNames.map(Number);
  try {
    const response = await axios.post(
      "https://altablue.cloud/api/drivermanagers/create",
      {
        email,
        password,
        companies,
        firstName,
        lastName
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.data.success) {
      message.success("Successfully Created Driver Manager");
      setShowForm(false);
      reload(true);
    } else {
      setError(response.data.error);
      message.error(`${response.data.error}`);
    }
  } catch (error) {
    setError(error.response.data.error);
    message.error(`${error.response.data.error}`);
  }
};

const handleEditSubmit = async () => {

 const companies = editManager.companies?.map(company => Number(company.id))

  try {
    const response = await axios.patch(
      `https://altablue.cloud/api/drivermanagers/companies/${id}`,
      {
        newEmail : editManager.email,
        companies,
        password : editManager.password,
        firstName : editManager.fname,
        lastName : editManager.lname
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (response.data.success) {
      message.success("Driver Manager updated successfully");
      handleCloseForm();
      reload(true);
    } else {
      message.error(`${response.data.error}`);
      setError(response.data.error);
    }
  } catch (error) {
    setError(error.response.data.error);
  }
};

const handleCloseForm = () => {
  setShowForm(false);
  setShowEditForm(false);
  setCompanyNames([]);
  setEmail("");
  setId(null);
  setPassword("");
  setConfirmPassword("");
  setError("");
  setEditManager(null);
  form.resetFields();
};

const truncate = (str) => (str.length > 5 ? `${str.slice(0, 5)}...` : str);

useEffect(() => {

  if(!data) {
    return;
  }
  setFilteredData(
    data.filter((manager) =>
      manager.email.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
}, [searchQuery, data]);


  return (
    <div className="companyDetailsection">
      <Col
        className="companyDetailsProperty"
        xs={24}
        sm={23}
        md={23}
        lg={23}
        xl={23}
      >
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <div className="companyDetailsNavbar">
          <h3>Manage Driver Manager</h3>
          {/* <Button type="primary" onClick={handleButtonClick}>
            Add Driver Manager
          </Button> */}

          <div>
          <Button className="saveButton" onClick={handleButtonClick}>
            + Add Driver manager
          </Button>

          <Select
          disabled = {!data}
      defaultValue= {1}
      style={{
        width: 120,
        marginRight : '12px',
      }}
      onChange={handleFilterChange}
      options={[
        {
          value: 1,
          label: 'Alphabetical Order',
        },
        {
          value: 2,
          label: 'Oldest to Newest',
        },
        {
          value: 3,
          label: 'Newest to Oldest',
        },
       
      ]}
    />

<Select
      defaultValue= {1}
      style={{
        width: 120,
      }}
      onChange={handlePerPageChange}
      options={[
        {
          value: 1,
          label: '5 per page',
        },
        {
          value: 2,
          label: '10 per page',
        },
        {
          value: 3,
          label: '15 per page',
        },
       
      ]}
    />
          </div>
        

        </div>
        <div className="companyHeadingNav">
          <div className={!smallScreen ? "companyNavSmall" : "companyNav"}>
            <div>Email Address</div>
            <div>User Name</div>
            <div>Password</div>
            <div>Company Name</div>
            {smallScreen && <div>Modify</div>}
          </div>
          <div>
            {currentPageData.map((d, index) => (
              <div
                style={
                  !smallScreen && index % 2 !== 0
                    ? {
                        backgroundColor: "#e8ffff",
                      }
                    : {}
                }
                className="mainData"
                key={d.id}
              >
                <div
                  className={
                    !smallScreen
                      ? "companyHeadingBodySmall"
                      : "companyHeadingBody"
                  }
                  key={d.id}
                >
                  <Tooltip title={d.email}>
                    <div>{d.email}</div>
                  </Tooltip>
                  <Tooltip title={d.fname}>
                    <div>{d.fname + " " + d.lname}</div>
                  </Tooltip>
                  <Tooltip title={d.password}>
                    <div>{d.password}</div>
                  </Tooltip>
                  <Tooltip  
                  title={d.companies?.length
                 ? d.companies.map(company => company.name).join(", ")
                   : "No Companies"}>
                     <div>
                  {d.companies?.length
                  ? d.companies.map(company => truncate(company.name)).join(", ")  // Truncate the company names for display
                   : "No Companies"}  
                     </div>
                  </Tooltip>
                  {smallScreen && (
                    <div className="center editButtons">
                      <Button
                      
                        onClick={() => handleEditButtonClick(d)}
                        className="editButton"
                      >
                        Edit
                      </Button>
                      <Button
                        className="deleteButton"
                        danger
                        onClick={() => handleDeleteManager(d.id)}
                      >
                        Delete
                      </Button>
                    </div>
                  )}
                </div>
                {!smallScreen && (
                  <div className="center editButtons shiftRightButton">
                    <Button
                      
                      className="editButton"
                      // onClick={() => handleEditClick(d)}
                    >
                      Edit
                    </Button>
                    <Button
                      className="deleteButton"
                      onClick={() => handleDeleteManager(d.id)}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="pagination">
            <div>
              {currentPageData.length > 0 && (
                <span>
                  Showing {(currentPage - 1) * pageSize + 1} to{" "}
                  {Math.min(currentPage * pageSize, filteredData.length)} out of{" "}
                  {filteredData.length} entities
                </span>
              )}
            </div>
            <Pagination
              current={currentPage}
              total={data ? data.length : 1}
              pageSize={pageSize}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </Col>

      <Modal
        title="Add Manager Form"
        open={showForm}
        onCancel={handleCloseForm}
        footer={null}
      >
        {/* {error && <p className="error">{error}</p>} */}
        <Form className="popupFooter" layout="vertical">
          {/* <Form.Item label="Company Names">
            <Checkbox.Group
              options={companyData.map((company) => ({
                label: company.name,
                value: company.id.toString(),
              }))}
              value={companyNames}
              onChange={handleCompanyChange}
            />
          </Form.Item> */}

          <Form.Item label="Company Names">
          <Select
          mode="multiple"
          allowClear
          style={{
          width: '100%',
            }}
      placeholder="Please Select"
      onChange={handleCompanyChange}
      options={companyData.map((company) => ({
        label: company.name,
        value: company.id.toString(),
      }))}
    />
          </Form.Item>

          <Form.Item label="Email ID" required>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            </Form.Item>
            <Form.Item label="First Name" required>
            <Input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            </Form.Item>
             <Form.Item label="Last Name" required>
            <Input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Password" required>
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Confirm Password" required>
            <Input.Password
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <div className="popupFooter">
              <Button
                className="saveButton"
                onClick={handleSubmit}
                type="primary"
                htmlType="submit"
                disabled = {companyNames.length == 0}
              >
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Edit Manager Form"
        open={showEditForm}
        onCancel={handleCloseForm}
        footer={[
          <Button className="saveButton" type="primary" htmlType="submit" 
          onClick={handleEditSubmit} 
          disabled = {!editManager?.fname || !editManager?.lname || !editManager?.password || !editManager.email}>
          Save
        </Button>
        ]}
      >
        {error && <p className="error">{error}</p>}

        {editManager && (
        <div className="popupFooter">

<div>
            Company Names
            <Select
          mode="multiple"
          allowClear
          style={{
          width: '100%',
            }}
            value={editManager.companies?.map(company => company.id.toString())}
      placeholder="Please Select"
      onChange={(value) =>
        setEditManager({
          ...editManager,
          companies: companyData.filter(company => value.includes(company.id.toString())) // Update with selected company objects
        })
      }
      options={companyData.map((company) => ({
        label: company.name,
        value: company.id.toString(),
      }))}
    />
          </div>

          <div className="addModeInput">
            <div>
              First Name
              <Input
                placeholder="First Name"
                value={editManager.fname}
                onChange={(e) =>
                  setEditManager({ ...editManager, fname: e.target.value })
                }
              />
            </div>

            <div>
              Last Name
              <Input
                placeholder="Last Name"
                value={editManager.lname}
                onChange={(e) =>
                  setEditManager({ ...editManager, lname: e.target.value })
                }
              />
            </div>
          </div>

          <div>
            Email
            <Input
              placeholder="Email"
              value={editManager.email}
              onChange={(e) =>
                setEditManager({ ...editManager, email: e.target.value })
              }
            />
          </div>

          <div>
            Password
            <Input.Password
              placeholder="Enter Password"
              value={editManager.password}
              onChange={(e) =>
                setEditManager({ ...editManager, password: e.target.value })
              }
            />
          </div>

         
        </div>)}
       
      </Modal>

      <Modal
        open={isDeleteModalVisible}
        onCancel={handleDeleteModalCancel}
       footer={
        !error ? 
        [
          <div style={
            {
              display : 'flex',
              justifyContent : 'center',
              width : '100%'
            }
          }>
          <Button 
          className = "saveButton" 
          key="submit" type="primary" 
          style={{marginRight : '24px'}}
          onClick={handleDeleteManagerSubmit}>
          Yes
        </Button>
          <Button key="no" onClick={() => handleDeleteModalCancel()}>
            No
          </Button>
          </div>
        ] : 
        [
          <div> </div>
         
        ] 
      }
      >
       <h3 style={{textAlign : 'center',margin : '6px 0'}}>Delete Driver Manager</h3>
         {!error ?  <>
          <p style={{textAlign : 'center', marginBottom : '4px'}}>Are You sure you want to delete this driver manager ?</p>
          <p style={{textAlign : 'center', marginBottom : '4px'}}>This action will permanently delete this driver manager and can not be undone.</p>
         </>  : 
          <>
          <p>Error deleting the driver manager.</p>
          </>
         }
      
      </Modal>


    </div>
  );
};

export default ManagerList;
