import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  Pagination,
  Tooltip,
  Modal,
  Form,
  Input,
  message,
  Select,
} from "antd";
import SearchBar from "../searchbar/SearchBar";
import axios from "axios";
import { useNavItem } from "../nav/NavContext";

const { Option } = Select;

const DriverList = ({ data, reload }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [smallScreen, setSmallScreen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { token } = useSelector((state) => state.auth);
  const [pageSize, setPageSize] = useState(5);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [editDriver, setEditDriver] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const [currentSelectedDriver, setCurrentSelectedDriver] = useState(null);
  const [currentFilterValue, setCurrentFilterValue] = useState(1);
  const [error, setError] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const { setSelectedItem, selectedItem } = useNavItem();

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth > 1168);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handlePerPageChange = (value) => {
    if (value === 1) {
      setPageSize(5);
    } else if (value === 2) {
      setPageSize(10);
    } else if (value === 3) {
      setPageSize(15);
    }
  };

  const handleFilterChange = (value) => {
    setCurrentFilterValue(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://altablue.cloud/api/companies/all",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setCompanyData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    if ((isAddModalVisible || isEditModalVisible) && companyData.length === 0) {
      fetchData();
    }
  }, [isAddModalVisible, isEditModalVisible]);

  useEffect(() => {
    const applyFilters = () => {
      let newData = data;

      // Filter by search query
      if (searchQuery) {
        newData = newData.filter((driver) =>
          driver?.company?.name
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      }

      // Sort data based on filter value
      if(newData) {
      if (currentFilterValue === 1) {
        newData = newData.sort((a, b) => a.email.localeCompare(b.email));
      } else if (currentFilterValue === 2) {
        newData = newData.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      } else if (currentFilterValue === 3) {
        newData = newData.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
      } }

      setFilteredData(newData);

      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = Math.min(startIndex + pageSize, (newData ? newData.length : 0));
     if(newData)
      setCurrentPageData(newData.slice(startIndex, endIndex));
    };

    applyFilters();
  }, [searchQuery, currentFilterValue, currentPage, pageSize, data]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const truncate = (str) => (str.length > 5 ? `${str.slice(0, 5)}...` : str);

  const handleAddDriver = () => {
    setIsAddModalVisible(true);
  };

  const handleEditDriver = (driver) => {
    setEditDriver(driver);
    setIsEditModalVisible(true);
  };

  const handleDeleteDriver = (id) => {
    setCurrentSelectedDriver(id);
    setIsDeleteModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsAddModalVisible(false);
    setIsEditModalVisible(false);
    setEditDriver(null);
  };

  const handleDeleteModalCancel = () => {
    setIsDeleteModalVisible(false);
    setCurrentSelectedDriver(null);
    setError(false);
  };

  const handleAddDriverSubmit = async (values) => {
    try {
      if (values.password !== values.confirmPassword) {
        message.error("Password and confirm password don't match");
        return;
      }
      const response = await fetch(
        "https://altablue.cloud/api/drivers/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            companyId: values.companyId,
            email: values.email,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create driver");
      }

      message.success("Driver added successfully");
      setIsAddModalVisible(false);
      reload();
    } catch (error) {
      message.error("Error adding driver: " + error.message);
    }
  };

  const handleDeleteDriverSubmit = async () => {
    try {
      const response = await axios.delete(
        `https://altablue.cloud/api/drivers/${currentSelectedDriver}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      message.success("Driver deleted successfully");
      setIsDeleteModalVisible(false);
      setError(false);
      reload();
    } catch (error) {
      message.error("Error deleting driver");
      setError(true);
    }
  };

  const handleEditDriverSubmit = async () => {
    if (!editDriver) return;

    try {
      const response = await fetch(
        `https://altablue.cloud/api/drivers/${editDriver.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            newEmail: editDriver.email,
            password: editDriver.password,
            firstName : editDriver.fname,
            lastName : editDriver.lname
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update driver");
      }

      message.success("Driver updated successfully");
      setIsEditModalVisible(false);
      setEditDriver(null);
      reload();
    } catch (error) {
      message.error("Error updating driver: " + error.message);
    }
  };
  return (
    <div className="companyDetailsection">
      <Col
        className="companyDetailsProperty"
        xs={24}
        sm={23}
        md={23}
        lg={23}
        xl={23}
      >
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <div className="companyDetailsNavbar">
          <h3>Manage Driver</h3>
          <div>
          <Button className="saveButton" onClick={handleAddDriver}>
            + Add Driver
          </Button>

          <Select
          disabled = {!data}
      defaultValue= {1}
      style={{
        width: 120,
        marginRight : '12px',
      }}
      onChange={handleFilterChange}
      options={[
        {
          value: 1,
          label: 'Alphabetical Order',
        },
        {
          value: 2,
          label: 'Oldest to Newest',
        },
        {
          value: 3,
          label: 'Newest to Oldest',
        },
       
      ]}
    />


      <Select
      defaultValue= {1}
      style={{
        width: 120,
      }}
      onChange={handlePerPageChange}
      options={[
        {
          value: 1,
          label: '5 per page',
        },
        {
          value: 2,
          label: '10 per page',
        },
        {
          value: 3,
          label: '15 per page',
        },
       
      ]}
    />
    </div>

        </div>

        <div className={!smallScreen ? "companyNavSmall" : "companyNav"}>
          <div>Company Name</div>
          <div>Email Address</div>
          <div>User Name</div>
          <div>Password</div>
          {smallScreen && <div>Modify</div>}
        </div>

        <div>
          {currentPageData.map((driver, index) => (
            <div
              style={
                !smallScreen && index % 2 !== 0
                  ? {
                      backgroundColor: "#e8ffff",
                    }
                  : {}
              }
              className="mainData"
              key={driver.id}
            >
              <div
                className={
                  !smallScreen
                    ? "companyHeadingBodySmall"
                    : "companyHeadingBody"
                }
              >
                <Tooltip title={driver.company.name}>
                  <div>{driver.company.name}</div>
                </Tooltip>
                <Tooltip title={driver.email}>
                  <div>{driver.email}</div>
                </Tooltip>
                <Tooltip title={driver.fname}>
                  <div>{(driver.fname + " " + driver.lname).toString()}</div>
                </Tooltip>
                <Tooltip title={driver.password}>
                  <div>{driver.password}</div>
                </Tooltip>
                {smallScreen && (
                  <div className="center editButtons">
                    <button
                       className="editButton"
                     
                      onClick={() => handleEditDriver(driver)}
                    >
                      Edit
                    </button>
                    <Button
                      className="deleteButton"
                      onClick={() => handleDeleteDriver(driver.id)}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </div>
              {!smallScreen && (
                <div className="center editButtons shiftRightButton">
                  <button
                    className="editButton"
                   
                    onClick={() => handleEditDriver(driver)}
                  >
                    Edit
                  </button>
                  <Button
                    className="deleteButton"
                    onClick={() => handleDeleteDriver(driver.id)}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="pagination">
          <div>
            {currentPageData.length > 0 && (
              <span>
                Showing {(currentPage - 1) * pageSize + 1} to{" "}
                {Math.min(currentPage * pageSize, filteredData.length)} out of{" "}
                {filteredData.length} entities
              </span>
            )}
          </div>
          <div></div>
          <Pagination
            current={currentPage}
            total={filteredData ? filteredData.length : 1}
            pageSize={pageSize}
            onChange={handlePageChange}
          />
        </div>
      </Col>

      <Modal
        title="Add Driver"
        open={isAddModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form onFinish={handleAddDriverSubmit}>
          <Form.Item
            label="Company Name"
            name="companyId"
            rules={[
              { required: true, message: "Please select the company name!" },
            ]}
          >
            <Select>
              {companyData.map((company) => (
                <Option key={company.id} value={company.id}>
                  {company.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: "Please input the first Name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Please input the last Name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input the email!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input the password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[{ required: true, message: "Please input the confirm password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button className="saveButton" type="primary" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Edit Driver"
        open={isEditModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <div className="popUpFooter">
            <Button
              className="saveButton"
              key="save"
              type="primary"
              onClick={handleEditDriverSubmit}
            >
              Save
            </Button>
          </div>
        ]}
      >
        {/* <Form
          initialValues={
            editDriver
              ? {
                  companyId: editDriver.company.id,
                  email: editDriver.email,
                  password: editDriver.password,
                  firstName : editDriver.fname,
                  lastName : editDriver.lname

                  // Placeholder, should be left empty or set a default value
                }
              : {}
          }
          onFinish={handleEditDriverSubmit}
        >
          <Form.Item
            label="Company Name"
            name="companyId"
            rules={[
              { required: true, message: "Please select the company name!" },
            ]}
          >
            <Select disabled>
              {companyData.map((company) => (
                <Option key={company.id} value={company.id}>
                  {company.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: "Please input the First Name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Please input the Last Name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input the email!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Password" name="password">
            <Input.Password
              placeholder="Enter new password or leave blank"
            />
          </Form.Item>

          <Form.Item>
            <Button className="saveButton" type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form> */}

{editDriver && (
        <div className="popupFooter">
          <div className="addModeInput">
            <div>
              Company Name
              <Select
                disabled
                value={editDriver.company.id}
                style={{ width: '100%' }}
              >
                {companyData.map((company) => (
                  <Select.Option key={company.id} value={company.id}>
                    {company.name}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div>
              First Name
              <Input
                placeholder="First Name"
                value={editDriver.fname}
                onChange={(e) =>
                  setEditDriver({ ...editDriver, fname: e.target.value })
                }
              />
            </div>

            <div>
              Last Name
              <Input
                placeholder="Last Name"
                value={editDriver.lname}
                onChange={(e) =>
                  setEditDriver({ ...editDriver, lname: e.target.value })
                }
              />
            </div>
          </div>

          <div>
            Email
            <Input
              placeholder="Email"
              value={editDriver.email}
              onChange={(e) =>
                setEditDriver({ ...editDriver, email: e.target.value })
              }
            />
          </div>

          <div>
            Password
            <Input.Password
              placeholder="Enter new password"
              value={editDriver.password}
              onChange={(e) =>
                setEditDriver({ ...editDriver, password: e.target.value })
              }
            />
          </div>
        </div>
      )}

      </Modal>


      <Modal
        title="Delete Driver"
        open={isDeleteModalVisible}
        onCancel={handleDeleteModalCancel}
       footer={
        !error ? 
        [
          <div style={
            {
              display : 'flex',
              justifyContent : 'center',
              width : '100%'
            }
          }>
          <Button 
          className = "saveButton" 
          key="submit" type="primary" 
          style={{marginRight : '24px'}}
          onClick={handleDeleteDriverSubmit}>
          Yes
        </Button>
          <Button key="no" onClick={() => handleDeleteModalCancel()}>
            No
          </Button>
         </div>
        ] : 
        [
          <Button className = "saveButton" key="submit" type="primary" onClick={() => setSelectedItem({ 
            id : 4,
             url : "https://altablue.cloud/api/companies/all" })}>
          Manage Invoices
        </Button>
         
        ] 
      }
      >
       
         {!error ?  <>
          <p style={{textAlign : 'center', marginBottom : '4px'}}>Are You sure you want to delete this driver ?</p>
          <p style={{textAlign : 'center', marginBottom : '4px'}}>This action will permanently delete this driver and can not be undone.</p>
         </>  : 
          <>
          <p style={{textAlign : 'center', marginBottom : '4px'}}>There are invoices associated with this driver, Please first delete the invoices before deleting the driver.</p>
          <p>Note : Invoices should be marked as completed before deleting.</p>
          </>
         }
      
      </Modal>

    </div>
  );
};

export default DriverList;
