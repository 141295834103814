import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd'; // Import Spin from Ant Design
import '../styles.css'; // Import your CSS file
import CompanyList from '../components/dashboard/CompanyList';
import { useNavItem } from '../components/nav/NavContext';
import ManagerList from '../components/dashboard/ManagerList';
import DriverList from '../components/dashboard/DriverList';
import InvoiceList from '../components/dashboard/InvoiceList';
import { Navigate } from 'react-router-dom';
import { logout } from '../components/redux/authSlice';

const Dashboard = () => {
  const { selectedItem } = useNavItem();
  const [state, setState] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const { token } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    return <Navigate to="/" />;
  };

  useEffect(() => {
    setLoading(true);
    setReload(false);

    const fetchData = async () => {
     
      try {
        const response = await axios.get(selectedItem.url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        

        setState(response.data);
        setLoading(false);
        setHasError(false);
      } catch (error) {
        setHasError(true);
        setLoading(false);
        if(error.response.status === 401) {
              handleLogout();
        }
       
      }
    };

    fetchData();
  }, [selectedItem, reload, token]);

  return (
    <>
      {loading ? (
        <div
          style={{
            height : "100vh"
          }}
          
          className="companyDetailsection center ">
          <Spin size="large" />
        </div>
      ) :  (
        
        <div>
         
          {selectedItem.id === 1 && (
            <CompanyList data={state.data} reload={setReload} />
          )}

          {selectedItem.id === 2 && (
            <ManagerList data={state.data} reload={setReload} />
          )}

          {selectedItem.id === 3 && (
            <DriverList data={state.data} reload={setReload} />
          )}

          {selectedItem.id === 4 && (
            <InvoiceList data={state.data} />
          )}
        </div>
      )}
    </>
  );
};

export default Dashboard;
