import React from "react";
// import logo from "../../assets/Lumate-Logo-lt 1.svg";

import { Row, Col } from "antd";
// import { CalendarCheck, CheckCircleFill } from "antd-icons";
import { MdOutlineCheckCircle } from "react-icons/md";

const PasswordResetDone = () => {
  return (
    <Row className="container">
      <Col xs={22} sm={18} md={15} lg={12} xl={10} className="innerContainer">
        <div className="innerBox">
          <div className="logo">
            {/* <img src={logo} /> */}
          </div>
          <div className="checkCircle">
            <MdOutlineCheckCircle style={{ color: "#00CB39" }} />
          </div>
          <div className="changePassword">
            <h3>Password Updated</h3>
            <p>Your password has been changed successfully</p>
            <p>Use your new password to login</p>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default PasswordResetDone;
