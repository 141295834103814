import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavItem } from "./NavContext";
import { CiMenuBurger } from "react-icons/ci";
import { RiLogoutBoxRLine } from "react-icons/ri";
const SideNav = () => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const [smallScreen, setSmallScreen] = useState(false);
  const [open, setOpen] = useState(!smallScreen ? false : true);
  const { setSelectedItem, selectedItem } = useNavItem();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1168) {
        setSmallScreen(false);
      } else {
        setSmallScreen(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleItemClick = (id, url) => {
    setSelectedItem({ id, url });
  };

  return (
    <div
      className={!open ? "sidenavbar" : "sidenavbarClose"}
      style={
        !smallScreen
          ? {
              position: "absolute",
              zIndex: "1",
            }
          : {
              zIndex: "1",
            }
      }

      // className="sidenavbar"
    >
      <div>
        {user && (
          <button
            className={
              smallScreen ? "hamBurgorMenu center" : "center hamBurgorMenu2"
            }
            //  className='hamBurgorMenu center'

            onClick={() => {
              setOpen(!open);
            }}
          >
            <CiMenuBurger />
          </button>
        )}
      </div>
      <ul style={{ margin: "20px 0" }}>
        {isLoggedIn && user && user.role === 1 && (
          <>
            <li
              className={
                selectedItem.id === 1 ? "selectedSideNavItem" : "sideNavItem"
              }
            >
              <Link
                className="sideNavbarLink"
                to="/dashboard"
                onClick={() => {
                  !smallScreen ? setOpen(!open) : setOpen(open);
                  handleItemClick(
                    1,
                    "https://altablue.cloud/api/companies/all"
                  );
                }}
              >
                Manage Company
              </Link>
            </li>
            <li
              className={
                selectedItem.id === 2 ? "selectedSideNavItem" : "sideNavItem"
              }
              // style={{ padding: "20px", backgroundColor: selectedItem && selectedItem.id === 2 ? '#add8e6' : '#f0f0f0' }}
            >
              <Link
                className="sideNavbarLink"
                to="/dashboard"
                onClick={() => {
                  !smallScreen ? setOpen(!open) : setOpen(open);
                  handleItemClick(
                    2,
                    "https://altablue.cloud/api/drivermanagers/all"
                  );
                }}
              >
                Driver Manager
              </Link>
            </li>
            <li
              className={
                selectedItem.id === 3 ? "selectedSideNavItem" : "sideNavItem"
              }
              // style={{ padding: "20px", backgroundColor: selectedItem && selectedItem.id === 3 ? '#add8e6' : '#f0f0f0' }}
            >
              <Link
                className="sideNavbarLink"
                to="/dashboard"
                onClick={() => {
                  !smallScreen ? setOpen(!open) : setOpen(open);
                  handleItemClick(3, "https://altablue.cloud/api/drivers/all");
                }}
              >
                Manage Drivers
              </Link>
            </li>
            <li
              className={
                selectedItem.id === 4 ? "selectedSideNavItem" : "sideNavItem"
              }
              // style={{ padding: "20px", backgroundColor: selectedItem && selectedItem.id === 4 ? '#add8e6' : '#f0f0f0' }}
            >
              <Link
                className="sideNavbarLink"
                to="/dashboard"
                onClick={() => {
                  !smallScreen ? setOpen(!open) : setOpen(open);
                  handleItemClick(
                    4,
                    "https://altablue.cloud/api/companies/all"
                  );
                }}
              >
                Manage Invoices
              </Link>
            </li>
          </>
        )}

        {isLoggedIn && user && user.role === 3 && (
          <>
            <li
              // style={{
              //   padding: "20px",
              //   backgroundColor:
              //     selectedItem && selectedItem.id === 1 ? "#add8e6" : "#f0f0f0",
              // }}
              className="selectedSideNavItem"
            >
              <Link
                to="/dashboard"
                onClick={() => {
                  !smallScreen ? setOpen(!open) : setOpen(open);
                  handleItemClick(
                    4,
                    `https://altablue.cloud/api/drivermanagers/companies/${user?.id}`
                  );
                }}
                style={{
                  width: "100%",
                  textDecoration: "none",
                  color : "white"
                }}
              >
                Manage Invoices
              </Link>
            </li>
          </>
        )}
      </ul>
      {!smallScreen && (
        <ul
          style={{
            position: "absolute",
            bottom: "62px",
            left: "8px",
          }}
          className="navbarRightSide "
        >
          {isLoggedIn ? (
            <div className="center">
              <div>{user?.email}</div>

              <Link to="/logout" className="logoutButton center">
                <RiLogoutBoxRLine /> Logout
              </Link>
            </div>
          ) : (
            <li>
              <Link
                onClick={() => {
                  !smallScreen ? setOpen(!open) : setOpen(open);
                }}
                to="/login"
              >
                Login
              </Link>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SideNav;
