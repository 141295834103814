// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './components/redux/store';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import TopNav from './components/nav/TopNav';
import Logout from './components/LogOut';
import SideNav from './components/nav/SideNav';
import { NavItemProvider } from './components/nav/NavContext';
import Dashboard from './pages/Dashboard';
import InvoiceDetail from './pages/InvoiceDetail';
import ForgetPassword from './pages/ForgetPassword';
import PublicRoute from './components/PublicRoute';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordDriver from './pages/ResetPasswordDriver';
import PasswordResetDone from './components/ChangePasswordDone';
import PasswordError from "./components/PasswordError";

const Unauthorized = () => {
  
  return <div>Unauthorized User. You don't have permission to access this page.</div>;
};

 

const App = () => {
  const [smallScreen, setSmallScreen] = useState(false);
    const { user } = useSelector((state) => state.auth);
 
   


   useEffect( () => {
   
     const handleResize = () => {
       setSmallScreen(window.innerWidth > 1168);
     };
     handleResize();
     window.addEventListener("resize", handleResize);
     return () => {
       window.removeEventListener("resize", handleResize);
     };
   }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}></PersistGate>
      <NavItemProvider>
        <Router>
          <div>{user && <TopNav />}</div>
          <div style={{ display: "flex", width: "100%" }}>
            {user && <SideNav />}
            <div style={{ width: "100%" }}>
              <Routes>
                {/* <Route path="/" element={<HomePage />} /> */}
                <Route
                  path="/login"
                  element={<PublicRoute element={Login} />}
                />
                <Route
                  path="/resetdone"
                  element={<PublicRoute element={PasswordResetDone} />}
                />
                <Route
                  path="/reseterror"
                  element={<PublicRoute element={PasswordError} />}
                />

                <Route
                  path="/forgot-password"
                  element={<PublicRoute element={ForgetPassword} />}
                />

                <Route
                  path="/user/reset-password/:token"
                  element={<PublicRoute element={ResetPassword} />}
                />
                <Route
                  path="/driver/reset-password/:token"
                  element={<PublicRoute element={ResetPasswordDriver} />}
                />

                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route
                  path="/"
                  element={<PrivateRoute element={Dashboard} role={[1, 3]} />}
                />

                <Route
                  path="/dashboard/invoices"
                  element={
                    <PrivateRoute element={InvoiceDetail} role={[1, 3]} />
                  }
                />
                <Route
                  path="/logout"
                  element={<PrivateRoute element={Logout} role={[1, 3]} />}
                />

                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </div>
          </div>
        </Router>
      </NavItemProvider>
    </Provider>
  );
};


export default App;


