import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";

const SearchBar = ({ searchQuery, setSearchQuery }) => {
  const [smallScreen, setSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth > 1168);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={smallScreen ? "searchBar" : "searchBar2"}>
      <CiSearch className="menuIcon2" />
      <input
        className="searchBarInput"
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
