import React, { createContext, useContext, useState } from 'react';
import { useSelector } from 'react-redux';

const NavItemContext = createContext();

export const NavItemProvider = ({ children }) => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const [selectedItem, setSelectedItem] = useState(user?.role !== 3 ? { id: 1, url: 'https://altablue.cloud/api/companies/all' } : {id:4, url : `https://altablue.cloud/api/drivermanagers/companies/${user?.id}`});

  return (
    <NavItemContext.Provider value={{ selectedItem, setSelectedItem }}>
      {children}
    </NavItemContext.Provider>
  );
};

export const useNavItem = () => useContext(NavItemContext);
